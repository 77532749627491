/** 列表 */
export const dqCheckResultDTypeConfirmListTableColumns = [
    { title: "鉴定单号", align: "center", scopedSlots: { customRender: "itemOrderNoSlot" }, width: 200 },
    { title: "评级编号", align: "center", dataIndex: 'ratingCode', width: 250},
    { title: "发货单号", align: "center", scopedSlots: { customRender: 'itemSenderExpressNoSlot'}},
    { title: "是否需要确版", align: "center", scopedSlots: { customRender: "itemVersionSlot" }},
    { title: "平台确认", align: "center", scopedSlots: { customRender: "itemDqConfirmSlot" }},
    { title: "送评用户", align: "center", scopedSlots: { customRender: "itemRateUserNameOrIdSlot" } },
    { title: "鉴定后买家选择", align: "center", scopedSlots: { customRender: "itemBuyerSelectedSlot" }, width: 100},
    { title: "操作人", align: "center", dataIndex: 'sysAdmin' },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" } },
]