var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.handleChangePage },
        scopedSlots: _vm._u([
          {
            key: "itemOrderNoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("span", { staticClass: "color-blue" }, [
                  _vm._v(_vm._s(row.orderNo))
                ])
              ])
            }
          },
          {
            key: "itemCheckResultSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  { class: _vm.rateResultClassType[row.identificationResults] },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedResultTypeText(row.identificationResults) ||
                          "暂无"
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemSenderExpressNoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    class: row.senderExpressNo
                      ? "color-green cur-pot"
                      : "color-gray"
                  },
                  [
                    _vm._v(
                      _vm._s(
                        row.senderExpressNo ? row.senderExpressNo : "未发货"
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemVersionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("span", { class: _vm.confirmClassType[row.duibanService] }, [
                  _vm._v(
                    _vm._s(
                      _vm.dTypeVersionStatusTypeText(row.duibanService) ||
                        "暂无"
                    )
                  )
                ])
              ])
            }
          },
          {
            key: "itemBuyerSelectedSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    class:
                      _vm.buyerSelAfterRatingClassType[row.buyerSelAfterRating]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedBuyerSelAfterRatingTypeListMapText(
                          row.buyerSelAfterRating
                        )
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemRateUserNameOrIdSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [_vm._v(_vm._s(row.ratingUserId))]),
                _c("div", [_vm._v(_vm._s(row.ratingUserName))])
              ])
            }
          },
          {
            key: "itemDqConfirmSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("span", { class: _vm.confirmClassType[row.reviewStatus] }, [
                  _vm._v(_vm._s(_vm.computedConfirmTypeText(row.reviewStatus)))
                ])
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowConfirm(row)
                        }
                      }
                    },
                    [_vm._v("确定价格")]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("ConfirmPricePopup", {
        ref: "confirmPricePopupEl",
        on: { success: _vm.handleChangeSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }