<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="handleChangePage"
    >
<!--   订单号   -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <span class="color-blue">{{ row.orderNo }}</span>
      </div>
<!--   鉴定结果   -->
      <div slot="itemCheckResultSlot" slot-scope="row">
        <span :class="rateResultClassType[row.identificationResults]">{{ computedResultTypeText(row.identificationResults) || '暂无' }}</span>
      </div>
<!--   发货单号   -->
      <div slot="itemSenderExpressNoSlot" slot-scope="row">
        <span :class="row.senderExpressNo ? 'color-green cur-pot' : 'color-gray'" >{{ row.senderExpressNo ? row.senderExpressNo : '未发货' }}</span>
      </div>
<!--   是否需要确版   -->
      <div slot="itemVersionSlot" slot-scope="row">
        <span :class="confirmClassType[row.duibanService]">{{ dTypeVersionStatusTypeText(row.duibanService) || '暂无' }}</span>
      </div>
<!--   鉴定后买家选择   -->
      <div slot="itemBuyerSelectedSlot" slot-scope="row">
        <span :class="buyerSelAfterRatingClassType[row.buyerSelAfterRating]">{{ computedBuyerSelAfterRatingTypeListMapText(row.buyerSelAfterRating) }}</span>
      </div>
<!--   送评用户   -->
      <div slot="itemRateUserNameOrIdSlot" slot-scope="row">
        <div>{{ row.ratingUserId }}</div>
        <div>{{ row.ratingUserName }}</div>
      </div>
<!--   平台确认   -->
      <div slot="itemDqConfirmSlot" slot-scope="row">
        <span :class="confirmClassType[row.reviewStatus]">{{ computedConfirmTypeText(row.reviewStatus) }}</span>
      </div>
<!--   操作  :disabled="row.reviewStatus === 1" -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button
            size="small"
            type="primary"
            @click="handleShowConfirm(row)"
        >确定价格</a-button>
      </div>
    </a-table>
    <ConfirmPricePopup ref="confirmPricePopupEl" @success="handleChangeSuccess"/>
  </div>
</template>
<script>
import {
  buyerSelAfterRatingClassType,
  buyerSelAfterRatingTypeListMapText,
  CONFIRM_CLASS_TYPE,
  dqCheckPayClassType, dTypeVersionStatusTypeMapText,
  RATE_RESULT_CLASS_TYPE_LIST,
  rateConfirmTypeMapText,
  rateResultTypeMapText,
} from "@/views/cmsPage/checkManage/_data"
import ConfirmPricePopup from "@/views/cmsPage/checkManage/DRateResultList/_components/ConfirmPricePopup"
import {dqCheckResultDTypeConfirmListTableColumns} from "@/views/cmsPage/checkManage/DRateResultList/_data"

export default {
  props: ['list', 'pagination'],
  components: {ConfirmPricePopup},
  data() {
    return {
      tableList: dqCheckResultDTypeConfirmListTableColumns,
      rateResultClassType: RATE_RESULT_CLASS_TYPE_LIST,
      confirmClassType: CONFIRM_CLASS_TYPE,
      dqCheckPayClassType,
      buyerSelAfterRatingClassType
    }
  },
  computed: {
    computedBuyerSelAfterRatingTypeListMapText() {
      return (value) => buyerSelAfterRatingTypeListMapText(value)
    },
    computedResultTypeText() {
      return (value) => rateResultTypeMapText(value)
    },
    computedConfirmTypeText() {
      return (value) => rateConfirmTypeMapText(value)
    },
    dTypeVersionStatusTypeText() {
      return (value) => dTypeVersionStatusTypeMapText(value)
    }
  },
  methods: {
    /** 确定价格 */
    handleShowConfirm(item) {
      this.$refs.confirmPricePopupEl.show(item.ratingId, item.reviewStatus)
    },
    /** 修改成功 */
    handleChangeSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    handleChangePage(page) {
      this.$emit('changePage', page)
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        current: index,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    handleConfirmSuccess() {
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss" scoped>
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
.color-green {
  color: green;
}
.color-red {
  color: red;
}
</style>